import React from 'react'
import { Helmet } from "react-helmet"
import ShareImage from '../../images/share.jpg'


const MetaData = (props) => {

  return (
    <Helmet>
      <title>{`${props.title ? `${props.title} | ` : ''}WE ARE FGR`}</title>
      <meta property="og:image" content={ShareImage} />
      <meta property="og:image:type" content="image/jpg" />
      <meta property="og:image:width" content="1680" />
      <meta property="og:image:height" content="1202" />
    </Helmet>
  )
}

export default MetaData